var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showIf
    ? _c("div", { staticClass: "vx-row mb-4" }, [
        _c(
          "div",
          {
            staticClass: "vx-col sm:w-1/5 w-full",
            staticStyle: { "align-content": "flex-end" },
          },
          [_c("span", [_vm._v(_vm._s(_vm.options.name))])]
        ),
        _c("div", { staticClass: "vx-col sm:w-4/5" }, [
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col w-5/6" },
              [
                _c("flat-pickr", {
                  staticClass: "w-full",
                  attrs: { config: { dateFormat: "Y-m-d", altInput: true } },
                  on: { input: _vm.updateValue },
                  model: {
                    value: _vm.dateModel,
                    callback: function ($$v) {
                      _vm.dateModel = $$v
                    },
                    expression: "dateModel",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col w-1/6 pl-0" },
              [
                _c("vs-button", {
                  staticClass: "w-full",
                  attrs: {
                    type: "filled",
                    color: "danger",
                    "icon-pack": "feather",
                    icon: "icon-x",
                    title: "Clear",
                  },
                  on: { click: _vm.clearValue },
                }),
              ],
              1
            ),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }