var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "vx-row mb-4" }, [
    _c(
      "div",
      {
        staticClass: "vx-col sm:w-1/5 w-full",
        staticStyle: { "align-content": "flex-end" },
      },
      [_c("span", [_vm._v(_vm._s(_vm.options.name))])]
    ),
    _c(
      "div",
      { staticClass: "vx-col sm:w-4/5 w-full" },
      [
        _vm.options.allowMultiple && _vm.options.taggable
          ? _c("v-select", {
              attrs: {
                value: _vm.selectedValue,
                placeholder: "Please type value and press enter",
                taggable: "",
                multiple: "",
              },
              on: { input: _vm.updateValue },
            })
          : _vm.options.allowMultiple
          ? _c("v-select", {
              attrs: {
                value: _vm.selectedValue,
                options: _vm.options.selectOptions,
                multiple: "",
              },
              on: { input: _vm.updateValue },
            })
          : _c("v-select", {
              attrs: {
                value: _vm.selectedValue,
                options: _vm.options.selectOptions,
              },
              on: { input: _vm.updateValue },
            }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }