var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "vx-row mb-4" }, [
    _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
      _c("span", [_vm._v(_vm._s(_vm.options.name))]),
    ]),
    _c(
      "div",
      { staticClass: "vx-col sm:w-4/5 w-full" },
      [
        _vm.options.isToggle
          ? _c("vs-switch", {
              attrs: { value: _vm.value },
              on: { input: _vm.updateValue },
            })
          : _c("vs-checkbox", {
              attrs: { value: _vm.value },
              on: { input: _vm.updateValue },
            }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }