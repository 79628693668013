var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "travio-center-container",
    { attrs: { "grid-width": "1/2" } },
    [
      _c("h2", { staticStyle: { color: "#636363" } }, [
        _vm._v("RSS Link Builder"),
      ]),
      _c("p", {
        staticClass: "mt-2 mb-4",
        domProps: { innerHTML: _vm._s(_vm.infoLink) },
      }),
      _c(
        "vx-card",
        [
          _c("div", { staticClass: "vx-row mb-6" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full" },
              [
                _c("label", [_vm._v("Output")]),
                _c("vs-input", {
                  staticClass: "w-full mt-2",
                  attrs: { name: "Output Link" },
                  model: {
                    value: _vm.rssOutputLink,
                    callback: function ($$v) {
                      _vm.rssOutputLink = $$v
                    },
                    expression: "rssOutputLink",
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "vx-col w-full" }, [
              _c(
                "div",
                { staticClass: "flex flex-wrap justify-end" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "mt-4 mr-4",
                      attrs: { type: "border", color: "danger" },
                      on: { click: _vm.handleCancel },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "vs-button",
                    {
                      directives: [
                        {
                          name: "clipboard",
                          rawName: "v-clipboard:copy",
                          value: _vm.rssOutputLink,
                          expression: "rssOutputLink",
                          arg: "copy",
                        },
                        {
                          name: "clipboard",
                          rawName: "v-clipboard:success",
                          value: _vm.onCopy,
                          expression: "onCopy",
                          arg: "success",
                        },
                      ],
                      staticClass: "mt-4",
                      attrs: {
                        "icon-pack": "feather",
                        icon: "icon-clipboard",
                        title: "Copy To Clipboard",
                      },
                    },
                    [_vm._v("Copy To Clipboard\n          ")]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _vm._l(_vm.linkParams, function (param, index) {
            return _c(
              "div",
              { key: index },
              [
                _c(
                  param.component,
                  _vm._b(
                    {
                      tag: "component",
                      on: { input: _vm.onUpdate },
                      model: {
                        value: _vm.outputValue[param.key],
                        callback: function ($$v) {
                          _vm.$set(_vm.outputValue, param.key, $$v)
                        },
                        expression: "outputValue[param.key]",
                      },
                    },
                    "component",
                    {
                      options: param,
                      applicationId: _vm.applicationId,
                      allValues: _vm.outputValue,
                    },
                    false
                  )
                ),
              ],
              1
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }