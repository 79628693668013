var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "vx-row mb-4" }, [
    _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
      _c("span", [_vm._v(_vm._s(_vm.options.name))]),
    ]),
    _vm.options.max && _vm.options.min
      ? _c(
          "div",
          { staticClass: "vx-col sm:w-4/5 w-full" },
          [
            _c("vs-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "between:" + _vm.options.min + "," + _vm.options.max,
                  expression: "'between:' + options.min + ',' + options.max",
                },
              ],
              staticClass: "w-full",
              attrs: {
                value: _vm.value,
                name: _vm.options.name,
                type: "number",
                max: _vm.options.max,
                min: _vm.options.min,
              },
              on: { input: _vm.updateValue },
            }),
            _c("span", { staticClass: "text-danger text-sm" }, [
              _vm._v(
                _vm._s(
                  _vm.errors.first(_vm.options.name)
                    ? `${_vm.options.name} must be bet. ${_vm.options.min} and ${_vm.options.max}`
                    : ""
                )
              ),
            ]),
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "vx-col sm:w-4/5 w-full" },
          [
            _c("vs-input", {
              staticClass: "w-full",
              attrs: {
                value: _vm.value,
                min: "0",
                name: _vm.options.name,
                type: "number",
              },
              on: { input: _vm.updateValue },
            }),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }